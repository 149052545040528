import DOMPurify from "dompurify";
import { MessageStimulusFilesItemResponseModel, MessageStimulusItemResponseModel, StimulusImageFileMetaViewModel, StimulusType } from "../../apiClient";
import { MediaMessagePart, Message, MessagePart, MessagePartType, MessageSide } from "../../models/Message";
import { sanitizerConfig } from "../utils";
import { MessagesContextType } from '../../models/MessagesContext'
import { ChatStateContextType } from "../../models/ChatStateContext";

export const processStimuliResponseModelAsync = async (
  messageId: number,
  stimulusData: MessageStimulusItemResponseModel,
  messagesContext: MessagesContextType,
  chatStateContext: ChatStateContextType,
  isInstant: boolean
) => {
  if (!stimulusData)
    throw Error('Stimulus data is null');

  switch (stimulusData.type) {
    case StimulusType.Text:
      const sanitizedDescription = DOMPurify.sanitize(stimulusData.description, sanitizerConfig);

      const message = {
        id: messageId,
        side: MessageSide.Bot,
        payload: [{
          type: MessagePartType.Text,
          data: sanitizedDescription
        }]
      };

      if (isInstant)
        await messagesContext.addMessageInstantAsync(message)
      else
        await messagesContext.enqueueMessageAsync(message)

      chatStateContext.setPinnedMessage({
        messageText: sanitizedDescription,
        imageUrl: '',
        aspectRatio: 0,
        id: messageId,
        title: stimulusData.pinnedMessageTitle
      });
      break;
    case StimulusType.Image:
      const stimulusImageData = stimulusData as MessageStimulusFilesItemResponseModel;

      const imageMessages = handleResponseItemStimuliImages(stimulusData);

      for (let i = 0; i < imageMessages.length; i++) {
        const msg = imageMessages[i];

        if (isInstant)
          await messagesContext.addMessageInstantAsync({ ...msg, id: messageId })
        else
          await messagesContext.enqueueMessageAsync({ ...msg, id: messageId })

        if (msg.payload.some(p => p.type === MessagePartType.Image)) {
          const imagePart = msg.payload.find(p => p.type === MessagePartType.Image) as MediaMessagePart;
          const sanitizedDescription = DOMPurify.sanitize(stimulusImageData.description, sanitizerConfig);

          chatStateContext.setPinnedMessage({
            messageText: sanitizedDescription,
            imageUrl: imagePart.previewUrl || imagePart.data,
            aspectRatio: imagePart.aspectRatio,
            id: messageId,
            title: stimulusImageData.pinnedMessageTitle
          });
        }
      }
      break;
    case StimulusType.Video:
      const stimulusVideoData = stimulusData as MessageStimulusFilesItemResponseModel;

      const videoMessages = handleResponseItemStimuliVideo(stimulusData);

      for (let i = 0; i < videoMessages.length; i++) {
        const msg = videoMessages[i];

        if (isInstant)
          await messagesContext.addMessageInstantAsync({ ...msg, id: messageId })
        else
          await messagesContext.enqueueMessageAsync({ ...msg, id: messageId })

        if (msg.payload.some(p => p.type === MessagePartType.Video)) {
          const videoPart = msg.payload.find(p => p.type === MessagePartType.Video) as MediaMessagePart;
          const sanitizedDescription = DOMPurify.sanitize(stimulusVideoData.description, sanitizerConfig);

          chatStateContext.setPinnedMessage({
            messageText: sanitizedDescription,
            imageUrl: videoPart.previewUrl,
            aspectRatio: videoPart.aspectRatio,
            id: messageId,
            title: stimulusVideoData.pinnedMessageTitle
          });
        }
      }
      break;
    default:
      break;
  }
}

const handleResponseItemStimuliImages = (stimulusData: MessageStimulusItemResponseModel): Message[] => {
  const stimuliData = stimulusData as MessageStimulusFilesItemResponseModel;

  if (!stimuliData)
    throw Error('Stimulus data is null');

  const messageParts: MessagePart[] = stimuliData.files.map<MediaMessagePart>(file => {
    const meta = file.info as StimulusImageFileMetaViewModel;
    return {
      type: MessagePartType.Image,
      data: file.file_url,
      aspectRatio: meta.aspect_ratio,
      width: meta.width,
      height: meta.height,
      previewUrl: file.file_preview_url
    }
  });
  if (stimuliData.description) {
    const sanitizedDescription = DOMPurify.sanitize(stimuliData.description, sanitizerConfig);
    messageParts.push({
      type: MessagePartType.Text,
      data: sanitizedDescription
    });
  }

  return [{
    side: MessageSide.Bot,
    payload: messageParts,
  }]
}

const handleResponseItemStimuliVideo = (stimulusData: MessageStimulusItemResponseModel): Message[] => {
  const stimuliData = stimulusData as MessageStimulusFilesItemResponseModel;

  if (!stimuliData)
    throw Error('Stimulus data is null');

  const messageParts: MessagePart[] = stimuliData.files.map<MediaMessagePart>(file => {
    const meta = file.info as StimulusImageFileMetaViewModel;
    return {
      type: MessagePartType.Video,
      data: file.file_url,
      aspectRatio: meta.aspect_ratio,
      width: meta.width,
      height: meta.height,
      previewUrl: file.file_preview_url
    }
  });
  if (stimuliData.description) {
    const sanitizedDescription = DOMPurify.sanitize(stimuliData.description, sanitizerConfig);
    messageParts.push({
      type: MessagePartType.Text,
      data: sanitizedDescription
    });
  }

  return [{
    side: MessageSide.Bot,
    payload: messageParts
  }]
}