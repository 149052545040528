import React from "react";
import { ChatClient, InterviewRole } from "../apiClient";
import { MessageSide } from "../models/Message";

export const FORCED_LANGUAGE_STORAGE_KEY = 'forced_language'
export const GREETINGS_QUESTION_ID = "Greetings"
export const ASSISTANT_NAME_QUESTION_ID = "AssistantName"

export const REGION: "RU" | "COM" | "YASNA" =
    process.env.REACT_APP_REGION === 'YASNA'
        ? 'YASNA'
        : process.env.REACT_APP_REGION === 'COM'
            ? 'COM'
            : 'RU';

export const SENTRY_URL = REGION === 'RU'
    ? 'sentry.survstat.ru'
    : 'sentry.fastuna.com';

export const ENVIRONMENT = process.env.NODE_ENV === "development"
    ? 'development'
    : process.env.REACT_APP_ENVIRONMENT === 'test'
        ? 'test'
        : 'production';

export const MIN_RESPONSE_TIME = 1000;

export const isDebugOrTest = () => ENVIRONMENT === 'development' || ENVIRONMENT === 'test';

export const backendUrl = ENVIRONMENT === "development"
    ? 'http://localhost:51665'
    : REGION === 'YASNA'
        ? ENVIRONMENT === 'test'
            ? 'https://yasna-chat-backend-test.corp.fastuna.com'
            : 'https://chat-backend.yasna.ai'
        : REGION === 'RU'
            ? ENVIRONMENT === 'test'
                ? 'https://chat-backend-test.corp.ai.fastuna.ru'
                : 'https://backend.makesense.chat'
            // REGION === COM
            : ENVIRONMENT === 'test'
                ? 'https://chat-backend-test.corp.ai.fastuna.com'
                : 'https://backend.indepth.chat';

export async function makeRedirect(url: string, timeout: number = 3000) {
    if (timeout > 0) {
        await sleepAsync(timeout);
    }
    window.location.replace(url);
};

export function toClientMessageSide(author: InterviewRole): MessageSide {
    return MessageSide[InterviewRole[author] as keyof typeof MessageSide];
    // switch (author) {
    //     case InterviewRole.Bot:
    //         return MessageSide.Bot;
    //     case InterviewRole.User:
    //         return MessageSide.User;
    //     case InterviewRole.System:
    //         return MessageSide.System;
    //     default:
    //         throw new Error(`Unknown author: ${author}`);
    // }
}


export async function sleepAsync(ms: number): Promise<void> {
    await new Promise((r) => setTimeout(r, ms))
}

function httpBehaviour(includeCredentials: boolean = false): { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> } {
    return ({
        async fetch(input, init) {
            const fetchOptions: RequestInit = {
                ...init,
                mode: "cors",
                headers: {
                    ...init?.headers,
                    "X-Location": window.location.href,
                }
            };

            if (includeCredentials) {
                fetchOptions.credentials = 'include';
            }

            const response = await fetch(input, fetchOptions);

            if (response.status === 401) {
                // TODO: редирект в сторону авторизации
            }

            return response;
        }
    });
}

export function useChatClient() {
    const result = React.useMemo(
        () => new ChatClient(backendUrl, httpBehaviour()),
        []
    );

    return result;
}

export function useChatClientWithCredentials() {
    const result = React.useMemo(
        () => new ChatClient(backendUrl, httpBehaviour(true)),
        []
    );

    return result;
}

export async function slowdownAsync(fn: () => Promise<void>, minTimeout: number = MIN_RESPONSE_TIME): Promise<void> {
    await Promise.all([fn(), sleepAsync(minTimeout)]);
}

export const CHAT_MESSAGE_MAX_WIDTH = 600;

export function iOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const sanitizerConfig = {
    ADD_ATTR: ["target"]
}

/** Определяет, имеет ли язык RTL-направление (по мере добавления таких языков, их нужно добавлять и сюда) */
export function isLanguageRTL(languageCode: string | null): boolean {
    switch (languageCode?.toUpperCase() ?? null) {
        case "HE":
        case "AR":
        case "UR":
            return true;
        default:
            return false;
    }
}

/** Глобальная переключалка направления всего UI в зависимости от языка в аргументе */
export function useTextDirection(languageCode: string | null): void {
    React.useEffect(
        () => {
            const initialDir: HTMLElement["dir"] = document.body.dir;

            if (isLanguageRTL(languageCode)) {
                document.body.dir = "rtl";
            }
            else {
                document.body.dir = "ltr";
            }

            return () => {
                document.body.dir = initialDir;
            };
        },
        [languageCode]
    );
}

/**
 * Хук для работы с CSS Media Query в React-компонентах
 * 
 * @example
 * const isMobile = useMediaQuery("(max-width: 767px)");
 * @param query 
 * @returns 
 */
export function useMediaQuery(query: string): boolean {

    const [value, setValue] = React.useState<boolean>(
        () => {
            var mediaQuery = window.matchMedia(query);
            return mediaQuery.matches;
        }
    );

    React.useEffect(
        () => {
            function mediaHandler(event: MediaQueryListEvent) {
                setValue(event.matches);
            }

            var mediaQuery = window.matchMedia(query);

            setValue(mediaQuery.matches);

            if (!!mediaQuery.addEventListener) {
                mediaQuery.addEventListener("change", mediaHandler);
            }
            else {
                // Для старых iOS
                mediaQuery.addListener(mediaHandler);
            }

            return () => {
                if (!!mediaQuery.removeEventListener) {
                    mediaQuery.removeEventListener("change", mediaHandler);
                }
                else {
                    // Для старых iOS
                    mediaQuery.removeListener(mediaHandler);
                }
            };
        },
        [query]
    );

    return value;
}

/** 
 * React-hook для проверки "мобильности" устройства по признаку наличия тачскрина:
 * * смартфоны -- вернет true
 * * ПК -- вернет false
 * * трансформеры:
 *   * если отстегнута клавиатура -- true
 *   * если пристегнута клавиатура -- false
 * */
export function useTouchScreen(): boolean {
    return useMediaQuery("(pointer: coarse)");
}
