import { ErrorMessageModel, HubMessageForceDisconnect, HubMessageInputAllowance, HubMessageProgress, HubMessageReaction, HubMessageRedirect, HubMessageStage, HubMessageStatus, HubMessageStimulus, HubMessageSyncId, HubMessageSystemDebugTopicInfo, HubMessageText, HubMessageToggleTyping, InterviewErrorResponseModel, InterviewRole, InterviewStatus, InterviewUpdateScoreResponseModel } from "../apiClient";
import { MessagesContextType } from "../models/MessagesContext";
import { Message, MessagePartType, MessageSide } from "../models/Message";
import { ChatStateContextType } from "../models/ChatStateContext";
import { processStimuliResponseModelAsync } from "./chatHubFunctions/processStimulusMessage";
import { makeRedirect } from "./utils";

export interface IChatHub {
    onUserInputAllowanceRecieved(message: HubMessageInputAllowance): Promise<void>;
    onProgressRecieved(message: HubMessageProgress): Promise<void>;
    onInterviewStatusRecieved(message: HubMessageStatus): Promise<void>;

    onTextMessageReceived(message: HubMessageText): Promise<void>;
    onInstantTextMessageReceived(message: HubMessageText): Promise<void>;
    onStimuliMessageReceived(message: HubMessageStimulus): Promise<void>
    onReactionMessageReceived(message: HubMessageReaction): Promise<void>;
    onSendSystemDebugMessageToClient(message: HubMessageSystemDebugTopicInfo): Promise<void>;

    onRedirectReceived(message: HubMessageRedirect): Promise<void>;
    onSyncMessageReceived(message: HubMessageSyncId): Promise<void>;
    onToggleInterviewerTypingReceived(message: HubMessageToggleTyping): Promise<void>;
    onChangeInterviewStageReceived(message: HubMessageStage): Promise<void>;

    onInterviewErrorReceived(message: InterviewErrorResponseModel): Promise<void>;
    onErrorReceived(message: ErrorMessageModel): Promise<void>;
    onInterviewScoreUpdateReceived(message: InterviewUpdateScoreResponseModel): Promise<void>;
    onForceDisconnectClientReceived(message: HubMessageForceDisconnect): Promise<void>;
}

export class HubImplementation implements IChatHub {

    messagesContext: MessagesContextType;
    chatStateContext: ChatStateContextType;

    constructor(messageContext: MessagesContextType, chatStateContext: ChatStateContextType) {
        this.messagesContext = messageContext;
        this.chatStateContext = chatStateContext;
    }

    async onUserInputAllowanceRecieved(message: HubMessageInputAllowance) {
        this.chatStateContext.setIsInputBlocked(!message.allow);
    }

    async onProgressRecieved(message: HubMessageProgress): Promise<void> {
        this.chatStateContext.setProgress(message.progress);
    }

    async onInterviewStatusRecieved(message: HubMessageStatus): Promise<void> {
        this.chatStateContext.setInterviewStatus(message.status);
        this.chatStateContext.setStatusText(message.statusText);
    }

    async onTextMessageReceived(message: HubMessageText): Promise<void> {
        this.chatStateContext.setCurrentQuestionId(message.question_id);

        const msg: Message = {
            id: message.id,
            side: MessageSide[InterviewRole[message.author] as keyof typeof MessageSide],
            payload: [{
                type: MessagePartType.Text,
                data: message.text
            }],
            reaction: null
        }
        await this.messagesContext.enqueueMessageAsync(msg);
    }

    async onInstantTextMessageReceived(message: HubMessageText): Promise<void> {
        this.chatStateContext.setCurrentQuestionId(message.question_id);

        const msg: Message = {
            id: message.id,
            side: MessageSide[InterviewRole[message.author] as keyof typeof MessageSide],
            payload: [{
                type: MessagePartType.Text,
                data: message.text
            }],
            reaction: null
        }
        await this.messagesContext.addMessageInstantAsync(msg);
    }

    async onStimuliMessageReceived(message: HubMessageStimulus): Promise<void> {
        this.chatStateContext.setCurrentQuestionId(message.question_id);

        await processStimuliResponseModelAsync(message.id, message.stimulusData, this.messagesContext, this.chatStateContext, false);

        const msg: Message = {
            id: message.id,
            side: MessageSide.Bot,
            payload: [{
                type: MessagePartType.Text,
                data: message.text
            }],
            reaction: null
        }
        await this.messagesContext.enqueueMessageAsync(msg);
    }

    async onReactionMessageReceived(message: HubMessageReaction): Promise<void> {
        await this.messagesContext.addReaction(message.targetMessageId, message.reaction);
    }

    async onSendSystemDebugMessageToClient(message: HubMessageSystemDebugTopicInfo): Promise<void> {
        await this.messagesContext.addDebugMessage({
            targetMessageId: message.targetMessageId,
            visibleText: `Debug info: ${message.topic.text}`,
            fulltext: JSON.stringify(message.topic, null, 2),
            position: message.position
        });
    }

    async onSyncMessageReceived(message: HubMessageSyncId): Promise<void> {
        await this.messagesContext.syncMessage(message.clientId, message);
    }

    async onToggleInterviewerTypingReceived(message: HubMessageToggleTyping): Promise<void> {
        await this.messagesContext.setIsModeratorTypingAsync(message.isTyping);
    }

    async onChangeInterviewStageReceived(message: HubMessageStage): Promise<void> {
        this.chatStateContext.setInterviewStage(message.stage);
    }

    async onRedirectReceived(message: HubMessageRedirect): Promise<void> {
        await makeRedirect(message.redirectUrl);
    }

    async onInterviewErrorReceived(message: InterviewErrorResponseModel): Promise<void> {
        await this.messagesContext.setIsModeratorTypingAsync(false);
    }

    async onErrorReceived(message: ErrorMessageModel): Promise<void> {
        await this.messagesContext.setIsModeratorTypingAsync(false);
    }
    async onInterviewScoreUpdateReceived(message: InterviewUpdateScoreResponseModel): Promise<void> {
        this.chatStateContext.setInterviewScore(message.score);
    }
    async onForceDisconnectClientReceived(message: HubMessageForceDisconnect): Promise<void> {
        await this.messagesContext.setIsModeratorTypingAsync(false);
    }
}