import { styled } from 'styled-components';
import image404 from '../../public/404.svg'

const WrongUrlScreen = () => {
  return (
    <WrongUrlScreenContainerStyled>
      <WrongUrlScreenImageStyled $image={image404} />
      <WrongUrlScreenTextStyled>
        Effortless conversations with anyone. A better way of talking with consumers for teams.
      </WrongUrlScreenTextStyled>
    </WrongUrlScreenContainerStyled>
  );
}

const WrongUrlScreenContainerStyled = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  justify-content: center;
`

interface FinalScreenImageStyledProps {
  $image: string;
}

const WrongUrlScreenImageStyled = styled.div<FinalScreenImageStyledProps>`
  background-image: url(${props => props.$image});
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 65%;
  @media (min-width: 800px) {
    font-size: 55%;
  };
  height: 30em;
  width: 35em;
  `

const WrongUrlScreenTextStyled = styled.div`
  margin: 0 1em;
`

export default WrongUrlScreen