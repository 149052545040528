import { useCallback, useContext, useEffect, useRef, useState } from "react"
import Avatar from "../Avatar"
import Dropdown, { DropdownOption } from "../Dropdown"
import { TypeAnimation } from 'react-type-animation';
import { useMediaQuery } from "../../services/utils"
import Button, { ButtonBackgroundColor } from "../Button"
import ChatStateContext from "../../models/ChatStateContext";
import ChatFooter from "../ChatFooter";
import TypingDots from "../TypingDots";
import { GreetingFooterStyled, GreetingAvatarNameStyled, GreetingAvatarStyled, GreetingBlockStyled, GreetingColumnBlockStyled, GreetingMessageBubbleStyled, GreetingMessageBubbleTextStyled, GreetingWrapperStyled } from "./Greeting.styled";

export interface GreetingProps {
  acceptButtonHandler: () => void,
  rejectButtonHandler: () => void,
  languageDropdownOptions: DropdownOption[],
  selectedLanguageCode: string,
  interviewUid: string,
  footerText: string
}


const Greeting = (props: GreetingProps) => {
  const [isTyping, setIsTyping] = useState<boolean>(true);
  const [isTypingSkipped, setIsTypingSkipped] = useState<boolean>(false);
  const [isAcceptButtonPressed, setIsAcceptButtonPressed] = useState<boolean>(false);
  const [isRejectButtonPressed, setIsRejectButtonPressed] = useState<boolean>(false);

  const chatStateContext = useContext(ChatStateContext);

  const TYPING_ANIMATION_SHOWN_CACHE_NAME = "TYPING_SHOWN";
  const getCurrentTypingCacheName = (interviewUid: string) => `${TYPING_ANIMATION_SHOWN_CACHE_NAME}_${interviewUid}`

  const isMobile = useMediaQuery("(max-width: 767px)");

  const rejectButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatStateContext.interviewUid) {
      const isTypingAnimationShown = localStorage.getItem(getCurrentTypingCacheName(chatStateContext.interviewUid));
      if (isTypingAnimationShown && isTypingAnimationShown === "1") {
        setIsTyping(false);
      }
    }
  }, [chatStateContext.interviewUid])

  const skipTypingClickHandler = () => {
    if (process.env.REACT_APP_ENVIRONMENT !== 'test' && window.location.hostname !== "localhost") return;

    setIsTyping(false);
    setIsTypingSkipped(true);
    rejectButtonRef.current!.scrollIntoView();
  }

  const typingSequenceEndHandler = useCallback(() => {
    if (rejectButtonRef.current) {
      setIsTyping(false);
      localStorage.setItem(getCurrentTypingCacheName(chatStateContext.interviewUid), "1");
      rejectButtonRef.current!.scrollIntoView();
    }
  }, [chatStateContext.interviewUid]);

  const acceptButtonClickHandler = useCallback(async () => {
    if (isAcceptButtonPressed || isRejectButtonPressed) return;
    setIsAcceptButtonPressed(true);
    await props.acceptButtonHandler();
    localStorage.removeItem(getCurrentTypingCacheName(chatStateContext.interviewUid))
  }, [chatStateContext.interviewUid, isAcceptButtonPressed, isRejectButtonPressed, props]);

  const rejectButtonClickHandler = useCallback(async () => {
    if (isAcceptButtonPressed || isRejectButtonPressed) return;
    setIsRejectButtonPressed(true);
    await props.rejectButtonHandler();
    localStorage.removeItem(getCurrentTypingCacheName(chatStateContext.interviewUid))
  }, [chatStateContext.interviewUid, isAcceptButtonPressed, isRejectButtonPressed, props]);

  return (
    <GreetingWrapperStyled className="wrapper">
      <GreetingBlockStyled className="top-block">
        <GreetingAvatarStyled>
          <Avatar
            imageUrl={chatStateContext.assistantImageUrl}
            width={isMobile ? "80%" : "100%"}
            height="auto"
          />
          <GreetingAvatarNameStyled>{chatStateContext.assistantName}</GreetingAvatarNameStyled>
        </GreetingAvatarStyled>
        <GreetingColumnBlockStyled onClick={skipTypingClickHandler}>
          <div style={{
            position: "relative",
          }}>
            <GreetingMessageBubbleStyled dir="auto" $hidden={isTypingSkipped} style={{
              position: "absolute",
            }}>
              <GreetingMessageBubbleTextStyled dir="auto">
                <TypeAnimation
                  sequence={[
                    chatStateContext.greetingModel.greetingText,
                    typingSequenceEndHandler
                  ]}
                  speed={75}
                  cursor={false}
                  preRenderFirstString={localStorage.getItem(getCurrentTypingCacheName(chatStateContext.interviewUid)) === "1"}
                />
              </GreetingMessageBubbleTextStyled>
            </GreetingMessageBubbleStyled>
            <GreetingMessageBubbleStyled $hidden={!isTypingSkipped}>
              <GreetingMessageBubbleTextStyled dir="auto">
                {chatStateContext.greetingModel.greetingText}
              </GreetingMessageBubbleTextStyled>
            </GreetingMessageBubbleStyled>
          </div>
          <GreetingBlockStyled $hide={isTyping}>
            <Button
              disabled={isAcceptButtonPressed || isRejectButtonPressed}
              backgroundColor={ButtonBackgroundColor.lightBlue}
              onClick={acceptButtonClickHandler}
            >
              {isAcceptButtonPressed ? (<TypingDots />) : chatStateContext.greetingModel.acceptButtonText}
            </Button>
            <Button
              disabled={isAcceptButtonPressed || isRejectButtonPressed}
              backgroundColor={ButtonBackgroundColor.lightBlue}
              ref={rejectButtonRef}
              onClick={rejectButtonClickHandler}
            >
              {isRejectButtonPressed ? (<TypingDots />) : chatStateContext.greetingModel.rejectButtonText}
            </Button>
          </GreetingBlockStyled>
          <GreetingFooterStyled>
            <ChatFooter text={props.footerText} />
            <Dropdown
              disabled={isAcceptButtonPressed || isRejectButtonPressed}
              options={props.languageDropdownOptions}
              selectedOptionCode={props.selectedLanguageCode}
            />
          </GreetingFooterStyled>
        </GreetingColumnBlockStyled>
      </GreetingBlockStyled>
    </GreetingWrapperStyled>
  )
}

export default Greeting

