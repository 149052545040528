import React, { useCallback } from "react";
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { backendUrl } from "./utils";
import { ErrorMessageModel, GetAcceptedLanguagesResponseModel, HubMessageInputAllowance, HubMessageProgress, HubMessageReaction, HubMessageRedirect, HubMessageStage, HubMessageStatus, HubMessageStimulus, HubMessageSyncId, HubMessageText, HubMessageTextRequest, HubMessageToggleTyping, InterviewAcceptRequestModel, InterviewErrorResponseModel, InterviewRole, InterviewStatus, MessageContinueRequestModel, MessageContinueResponseModel, MessageRejectRequestModel, InterviewUpdateScoreResponseModel, HubMessageSetScoreRequest, HubMessageSystemDebugTopicInfo, CountdownRequest, HubMessageForceDisconnect } from "../apiClient";
import MessagesContext from "../models/MessagesContext";
import { MessagePartType, MessageSide } from "../models/Message";
import ChatStateContext from "../models/ChatStateContext";
import { HubImplementation, IChatHub } from "./ChatHubImplementation";
import getAcceptedLanguages from './ChatHubActions/ChatHub_GetAcceptedLanguages';
import continueInterview from './ChatHubActions/ChatHub_Continue';
import { SendMessageRequest } from "../models/SendMessageRequest";
import { ConnectionState } from "../models/ConnectionStateEnum";

export interface UseChatHubProps {
    interviewUid: string;
    browserLanguage: string;
}

let connection: HubConnection;
let connectionBreakTimer: NodeJS.Timeout | null = null;
let reconnectionStartTimer: NodeJS.Timeout | null = null;

function createConnection(interviewUid: string) {
    const queryString = interviewUid ? `?interviewUid=${interviewUid}` : "";

    connection = new HubConnectionBuilder()
        .withUrl(`${backendUrl}/hub${queryString}`, {
            withCredentials: true,
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();

    connection.serverTimeoutInMilliseconds = 1000 * 60 * 10; // 10 minutes
}

/**
 * Пока этот хук можно использовать только в одном месте, т.к. при втором использовании навешиваются лишние обработчики на соединение
 * Быстро победить не получилось из-за контекстов
 * @returns 
 */
export function useChatHub(props: UseChatHubProps) {
    const messagesContext = React.useContext(MessagesContext);
    const chatStateContext = React.useContext(ChatStateContext);

    const implementation: IChatHub = React.useMemo(() => new HubImplementation(messagesContext, chatStateContext), [messagesContext, chatStateContext]);

    if (!connection) {
        createConnection(chatStateContext.interviewUid);
    }

    /// Проверка консистентности сообщений
    /// Все ответы сервера должны приходить в ответ на последнее сообщение пользователя, иначе триггерим перезагрузку сигнала и стейта
    const validateLastMessageId = useCallback((lastMessageIdToCheck: number): void => {
        const userMessages = messagesContext.getMessagesWithQueued().filter(x => x.side === MessageSide.User);
        let lastUserMsg = userMessages[userMessages.length - 1];

        if (lastMessageIdToCheck === -1 || !lastUserMsg?.id || lastUserMsg?.id === -1) {
            return;
        }

        if (lastMessageIdToCheck === lastUserMsg.id) {
            return;
        }
        else {
            //window.location.reload();
        }

    }, [messagesContext]);

    React.useEffect(
        () => {
            // Делать эти обработчики асинхронными с опаской
            // т.к. начнут выполняться парралельно и может сломаться проверка консистентности сообщений в validateLastMessageId
            const onUserInputAllowanceRecieved = (message: HubMessageInputAllowance) => {
                validateLastMessageId(message.lastMessageId)
                implementation.onUserInputAllowanceRecieved(message);
            }
            const onProgressReceived = (message: HubMessageProgress) => {
                validateLastMessageId(message.lastMessageId)
                implementation.onProgressRecieved(message);
            }

            const onInterviewStatusReceived = (message: HubMessageStatus) => {
                validateLastMessageId(message.lastMessageId)
                implementation.onInterviewStatusRecieved(message);
            }

            const onTextMessageReceived = (message: HubMessageText) => {
                validateLastMessageId(message.lastMessageId)
                implementation.onTextMessageReceived(message);
                messagesContext.setIsModeratorTypingAsync(false);
            }
            const onInstantTextMessageReceived = (message: HubMessageText) => {
                validateLastMessageId(message.lastMessageId)
                implementation.onInstantTextMessageReceived(message);
            }
            const onStimuliMessageReceived = (message: HubMessageStimulus) => {
                implementation.onStimuliMessageReceived(message);
                messagesContext.setIsModeratorTypingAsync(false);
            }

            const onReactionMessageReceived = (message: HubMessageReaction) => {
                validateLastMessageId(message.lastMessageId)
                implementation.onReactionMessageReceived(message);
            }
            const onSendSystemDebugMessageToClient = (message: HubMessageSystemDebugTopicInfo) => implementation.onSendSystemDebugMessageToClient(message);

            const onRedirectReceived = (message: HubMessageRedirect) => implementation.onRedirectReceived(message);
            const onSyncMessageReceived = (message: HubMessageSyncId) => implementation.onSyncMessageReceived(message);

            const onToggleInterviewerTypingReceived = (message: HubMessageToggleTyping) => {
                validateLastMessageId(message.lastMessageId)
                implementation.onToggleInterviewerTypingReceived(message);
            }

            const onChangeInterviewStageReceived = (message: HubMessageStage) => implementation.onChangeInterviewStageReceived(message);

            const onInterviewErrorReceived = (message: InterviewErrorResponseModel) => {
                connection.stop();
                implementation.onInterviewErrorReceived(message);
            }
            const onErrorReceived = (message: ErrorMessageModel) => {
                connection.stop();
                implementation.onErrorReceived(message);
            }

            const onInterviewScoreUpdateReceived = (message: InterviewUpdateScoreResponseModel) => implementation.onInterviewScoreUpdateReceived(message)

            const onForceDisconnectClientReceived = (message: HubMessageForceDisconnect) => {
                connection.stop();
                implementation.onForceDisconnectClientReceived(message);
            }

            connection.on('SendUserInputAllowanceToClient', onUserInputAllowanceRecieved);
            connection.on('SendProgressToClient', onProgressReceived);
            connection.on('SendInterviewStatusToClient', onInterviewStatusReceived);

            connection.on('SendTextMessageToClient', onTextMessageReceived);
            connection.on('SendInstantTextMessageToClient', onInstantTextMessageReceived);
            connection.on('SendAcquaintanceMessageToClient', onTextMessageReceived);
            connection.on('SendStimuliMessageToClient', onStimuliMessageReceived);
            connection.on('SendReactionMessageToClient', onReactionMessageReceived);
            connection.on('SendSystemDebugMessageToClient', onSendSystemDebugMessageToClient);

            connection.on('SendRedirectToClient', onRedirectReceived);
            connection.on('SyncMessageToClient', onSyncMessageReceived);
            connection.on('ToggleInterviewerTypingOnClient', onToggleInterviewerTypingReceived);
            connection.on('ChangeInterviewStageOnClient', onChangeInterviewStageReceived);

            connection.on('SendInterviewErrorToClient', onInterviewErrorReceived);
            connection.on('SendError', onErrorReceived);
            connection.on('UpdateInterviewScoreOnClient', onInterviewScoreUpdateReceived);
            connection.on('ForceDisconnectClient', onForceDisconnectClientReceived);

            return () => {
                connection.off('SendUserInputAllowanceToClient', onUserInputAllowanceRecieved);
                connection.off('SendProgressToClient', onProgressReceived);
                connection.off('SendInterviewStatusToClient', onInterviewStatusReceived);

                connection.off('SendTextMessageToClient', onTextMessageReceived);
                connection.off('SendInstantTextMessageToClient', onInstantTextMessageReceived);
                connection.off('SendAcquaintanceMessageToClient', onTextMessageReceived);
                connection.off('SendStimuliMessageToClient', onStimuliMessageReceived);
                connection.off('SendReactionMessageToClient', onReactionMessageReceived);
                connection.off('SendSystemDebugMessageToClient', onSendSystemDebugMessageToClient);

                connection.off('SendRedirectToClient', onRedirectReceived);
                connection.off('SyncMessageToClient', onSyncMessageReceived);
                connection.off('ToggleInterviewerTypingOnClient', onToggleInterviewerTypingReceived);
                connection.off('ChangeInterviewStageOnClient', onChangeInterviewStageReceived);

                connection.off('SendInterviewErrorToClient', onInterviewErrorReceived);
                connection.off('SendError', onErrorReceived);
                connection.off('UpdateInterviewScoreOnClient', onInterviewScoreUpdateReceived);
                connection.off('ForceDisconnectClient', onForceDisconnectClientReceived);
            };
        },
        [implementation, validateLastMessageId]
    );

    const acceptFn = React.useMemo(() => {
        return async (browserLanguage: string): Promise<void> => {
            const acceptMessageId = await messagesContext.addMessageInstantAsync({
                id: -1,
                side: MessageSide.User,
                payload: [{
                    type: MessagePartType.Text,
                    data: chatStateContext.greetingModel.acceptButtonText
                }],
                reaction: null
            });

            const message: InterviewAcceptRequestModel = {
                browserLanguage: browserLanguage,
                clientMessageId: acceptMessageId
            };
            return connection.send('Accept', message);
        }
    }, [chatStateContext.greetingModel.acceptButtonText, messagesContext]);

    const rejectFn = React.useMemo(() => {
        return async (message: MessageRejectRequestModel): Promise<void> => {
            return connection.send('Reject', message);
        }
    }, [chatStateContext.greetingModel.rejectButtonText, messagesContext]);

    const continueInterviewFn = React.useMemo(() => {
        return async (message: MessageContinueRequestModel, signal: AbortSignal): Promise<void> => {
            await continueInterview(message, messagesContext, chatStateContext, connection, signal);
        }
    }, [chatStateContext, messagesContext]);

    const getAcceptedLanguagesFn = React.useMemo(() => {
        return (signal: AbortSignal): Promise<GetAcceptedLanguagesResponseModel> => {
            return getAcceptedLanguages(connection, signal);
        }
    }, []);

    const sendFn = React.useMemo(() => {
        return async (message: SendMessageRequest): Promise<void> => {
            const messageId = await messagesContext.addMessageInstantAsync({
                side: MessageSide.User,
                payload: [{
                    type: MessagePartType.Text,
                    data: message.text
                }]
            });
            const hubMessage: HubMessageTextRequest = {
                clientMessageId: messageId,
                author: InterviewRole.User,
                text: message.text,
                question_id: message.question_id,
                browserLanguage: props.browserLanguage
            };
            return connection.send('Send', hubMessage);
        }
    }, [messagesContext, props.browserLanguage]);

    const aggregateFn = React.useMemo(() => {
        return async (messageId: number, questionId: string, haveStartCountDown: boolean): Promise<void> => {
            const hubMessage: CountdownRequest = {
                clientMessageId: messageId,
                question_id: questionId,
                browser_language: props.browserLanguage,
                have_start_count_down: haveStartCountDown
            };
            return connection.send('Aggregate', hubMessage);
        }
    }, [])

    const callContinue = React.useCallback(async () => {
        const continueRequestModel: MessageContinueRequestModel = {
            interviewUid: props.interviewUid,
            browserLanguage: props.browserLanguage
        }

        let abortController = new AbortController();

        await continueInterviewFn(continueRequestModel, abortController.signal);
        chatStateContext.setConnectionState(ConnectionState.Connected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.browserLanguage, props.interviewUid]);

    const connect = React.useCallback(() => {
        connection.start()
            .then(() => {
                if (!connection) return;

                if (connection.state !== HubConnectionState.Connected) return;

                //отслеживаем состояние соединения и рисуем всякие предупреждения в зависимости от
                connection.onreconnecting(() => {
                    chatStateContext.setConnectionState(ConnectionState.Reconnecting);
                });
                connection.onreconnected(() => {
                    //костыль для переподключения после обрыва соединения
                    //обнулить обработчик отвала просто так нельзя, только руками обнулить приватное поле _closedCallbacks :(
                    //нужно чтобы между переподключением и окончанием вызова /continue чат не показывал совсем страшную ошибку
                    (connection as any)._closedCallbacks = [];
                    //для случая, когда умирает бэкенд пересоздаём соединение полностью, т.к. без этого входящие сообщения не будут приниматься
                    connection.stop().then(() => {
                        createConnection(chatStateContext.interviewUid);

                        //здесь нужно ещё раз обновить значение состояния подключения на тот же Reconnecting, чтобы сработал эффект в Chat.tsx на переподключение
                        chatStateContext.setConnectionState(ConnectionState.Reconnecting);
                    })
                });
                connection.onclose(() => {
                    chatStateContext.setConnectionState(ConnectionState.Disconnected);
                });

                console.log(`Report hub is connected: ${connection.connectionId}`);

                callContinue();
            })
            .catch((x) => {
                console.warn("Report hub connection failed");
                chatStateContext.setConnectionState(ConnectionState.Disconnected);
                throw x;
                //showRequestError(x)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callContinue]);

    const internetStateOnlineHandler = React.useCallback(async () => {
        if (connectionBreakTimer || reconnectionStartTimer) {
            connectionBreakTimer && clearTimeout(connectionBreakTimer);
            reconnectionStartTimer && clearTimeout(reconnectionStartTimer);
        }
        if (connection.state !== HubConnectionState.Connected) {
            await connection.stop();
            connect();
        }
        else {
            chatStateContext.setConnectionState(ConnectionState.Connected);
        }
    }, [chatStateContext, connect])

    const internetStateOfflineHandler = React.useCallback(() => {
        //при обрыве соединения, ждём 15 секунд и "пытаемся" переподключиться
        //а потом ждём меньше времени, чем при ожидании ответа, и рвём соединение + еведомляем пользователя
        reconnectionStartTimer = setTimeout(() => {
            chatStateContext.setConnectionState(ConnectionState.Reconnecting);
            connectionBreakTimer = setTimeout(async () => {
                await connection.stop();
            }, 1000 * 60 * 1) // 1 minute
        }, 1000 * 15) // 15 seconds;

    }, [chatStateContext])

    React.useEffect(() => {
        window.addEventListener('online', internetStateOnlineHandler);
        window.addEventListener('offline', internetStateOfflineHandler);

        return () => {
            window.removeEventListener('online', internetStateOnlineHandler);
            window.removeEventListener('offline', internetStateOfflineHandler);
        }
    }, [internetStateOfflineHandler, internetStateOnlineHandler])

    const setLanguage = useCallback(async (language: string) => {
        let abortController = new AbortController();
        return await connection.invoke<string>('SetLanguage', language, abortController.signal);
    }, [])

    const setScoreFn = useCallback(async (score: HubMessageSetScoreRequest) => {
        return await connection.invoke('SetScore', score);
    }, []);

    return {
        connection: connection,
        connect: connect,
        callContinue: callContinue,
        accept: acceptFn,
        reject: rejectFn,
        getAcceptedLanguages: getAcceptedLanguagesFn,
        send: sendFn,
        aggregate: aggregateFn,
        setLanguage: setLanguage,
        setScore: setScoreFn,
    };
}